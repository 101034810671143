<template>
  <b-card
    no-body
  >
    <b-table
      :items="loads"
      :fields="tableFields"
      responsive
      class="position-relative"
    >
      <template #cell(actions)="data">
        <b-button
          :to="{ name: 'apps-confronts-load-check', params: { load: data.item } }"
          variant="primary"
        >
          <feather-icon icon="PlayIcon" />
          <span class="align-middle ml-50">{{ $t('confront.load.list.startCharging') }}</span>
        </b-button>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import {
  BCard,
  BTable,
  BButton,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'
import confrontService from '@/services/confront.service'

export default {
  components: {
    BCard,
    BTable,
    BButton,
  },
  setup() {
    /* Services */
    const { fetchLoads } = confrontService()
    /* Data */
    const loads = ref([])
    fetchLoads()
      .then(data => {
        loads.value = data
      })
    /* UI */
    const tableFields = [
      {
        key: 'control',
        label: i18n.t('Confront'),
      },
      {
        key: 'vehiculoPlacas',
        label: i18n.t('confront.load.list.labelLicensePlate'),
      },
      {
        key: 'vehiculoNumeroEconomico',
        label: i18n.t('confront.load.list.labelEconomicNumber'),
      },
      {
        key: 'actions',
        label: i18n.t('Lists.Actions'),
      },
    ]
    return {
      // Data
      loads,
      // UI
      tableFields,
    }
  },
}
</script>

<style>

</style>
